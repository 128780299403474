@import url("https://use.typekit.net/fbo6bum.css");

$breakpoint-s: 500px;
$breakpoint-m: 780px;
$white: #f5f5f5;
$lightgray: #ECECEC;
$mediumgray: #D2D2D2;
$gray: #606060;
$darkgray: #2d2d2d;
$black: #000000;
$color1: #f08080;

* {
  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
}

a {
  text-decoration: underline;
  &:link { color: $gray; }
  &:visited { color: $gray; }
  &:hover { color: $black; }
  &:active { color: $darkgray; }
  cursor: pointer;
}

button {
  border-radius: 200px;
  cursor: pointer;
  background-color: $darkgray;
  color: $white;
  border: none;
  padding: 10px 20px;
  font-size: min(15px + 0.2vw, 18px);
  font-family: 'Europa', sans-serif;
  font-weight: 300;
  line-height: min(25px + 0.2vw, 28px);
  border-style: none;

  &:hover { background-color: $black; }
  &:active { color: $mediumgray; }
  
  transition: background-color 300ms linear;
}

body {
  background: $lightgray;
  position: absolute;
  font-family: 'Europa', sans-serif;
  font-weight: 300;
  font-size: min(15px + 0.2vw, 18px);
  line-height: min(25px + 0.2vw, 28px);
  color: $gray;

  h1 {
    color: $darkgray;
    font-weight: 700;
    font-size: min(10px + 1.3vw + 1.3vh, 40px);
    line-height: min(20px + 1.3vw + 1.3vh, 60px);
    margin: 0 0 40px 0;
    font-family: 'Europa', sans-serif;
  }
}

.content {
  width: calc(92vw - 40px);
  max-width: 1300px;
  margin: auto;
}

.header {
  position: relative;
  top: min(4vw + 20px, 75px);

  .headerLogoVideo {
    position: relative;
    width: 120px;
    height: 120px;
    left: -24px;
    top: -24px;
  }

  .headerNav {
    position: absolute;
    right: 0;
    top: 0;
    text-align: right;
    line-height: 75px;

    a {
      margin: 0 0 0 40px;
      text-decoration: none;
    }
  }
}

.stage {
  position: relative;
  height: calc(96vh - 20px);
  max-height: 900px;
  min-height: 50vw;
}

.stageIntro {
  position: relative;
  width: calc(92vw - 40px);
  max-width: 880px;
  left: 50%;
  top: calc(50% - 80px);
  transform: translateX(-50%) translateY(-50%);

  h1 {
    margin: 15px 0 0 0;
    font-weight: 400;

    span {
      font-weight: 700;
    }
    
  }
}

.stageCta {
  position: relative;
  width: 100%;
  margin-top: 30px;

  p {
    margin: 0 0 40px 0;
  }

  button {
    margin: 20px 30px 20px 0;
  }
  span {
    white-space: pre;
    text-align: center;
    margin: 20px 0;
  }

  .stageCtaIcon {
    display: inline-block;
    vertical-align: sub;
    margin: 20px 30px 20px 0;

    &.stageCtaInstagram {
      width: 20px;
      height: 20px;
    }
    &.stageCtaLinkedin {
      width: 20px;
      height: 20px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.projectDescription03 svg {
  display: inline-block;
  vertical-align: sub;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.externalLink {
  top: -3px;
  width: 14px !important;
  height: 14px !important;
}

.projectSectionMain {
  width: 100%;
  background-color: $lightgray;
  
  .projectSection {
    width: 100%;
    margin: 60px 0 min(8vw + 40px, 160px) 0;
  }

  .projectImage {
    width: 100%;
    margin: 0;
    border-radius: min(5px + 1vw, 20px);
  
    @media screen and (max-width: $breakpoint-m) {
      margin-bottom: 10px;
    }
  }

  .projectDescription {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  
    ul {
      list-style: none;
      padding: 0;
    }
    b {
      color: $darkgray;
    }
  
    @media screen and (max-width: $breakpoint-m) {
      display: block;
      h1 {
        display: inline-block;
        margin-right: 20px;
      }
      p {
        display: inline-block;
      }
      ul {
        display: inline-block;
      }
      li {
        display: inline-block;
        margin-right: 20px;
      }
    }

    .projectDescription00 {
      width: 100%;
      padding: min(4vw, 40px) 10px;
      h1 {
        margin: 0;
      }

      @media screen and (max-width: $breakpoint-m) {
        padding: min(4vw, 40px) 0px;
      }
    }
  
    .projectDescription01 {
      padding: 0 10px;
      width: 48%;
  
      @media screen and (max-width: $breakpoint-m) {
        width: 100%;
        padding: min(2vw, 40px) 0px;
  
        h1 {
          margin: 0;
        }
      }
    }

    .projectDescription02 {
      width: 23%;
    }
    .projectDescription03 {
      width: 18%;
      white-space: pre;
    }
  
    .projectDescription02, .projectDescription03 {
      padding: 0 10px;

      h1 {
        white-space: nowrap;
      }
  
      @media screen and (max-width: $breakpoint-m){
        width: 100%;
        border-top: 1px solid $mediumgray;
        padding: min(2vw, 40px) 0px;
  
        b {
          display: none;
        }

        .projectDescription03Title {
          display: none;
        }
      }
    }
    .projectDescriptionYear {      
      @media screen and (max-width: $breakpoint-m) {
        width: 100%;
        border-top: 1px solid $mediumgray;
        padding: min(2vw, 40px) 0 0 0;
        margin-bottom: 0;
        line-height: 52px;
      }
    }
  }
}



.projectSectionGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 100px;

  @media screen and (max-width: $breakpoint-s) {
    display: block;
  }
  
  .projectSection {
    width: 46%;
    margin: 0 0 60px 0;

    @media screen and (max-width: $breakpoint-s) {
      width: 100%;
    }
  }

  .projectImage {
    width: 100%;
    margin: 0 0 10px 0;
    border-radius: min(5px + 1vw, 20px);
  }

  .projectDescription {
    h1 {
      display: inline-block;
      margin-right: 20px;
    }
    p {
      display: block;
    }
    ul {
      display: inline-block;
      list-style: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin-right: 20px;
    }
  
    .projectDescription01 {
      width: 100%;
      padding: min(2vw, 40px) 0 20px 0;

      h1 {
        margin: 0;
      }
    }
  
    .projectDescription02, .projectDescription03 {
      width: 100%;
      padding: 0px 0;

      p {
        margin: 0;
      }
      ul {
        margin-top: 0;
      }

      h1 {
        display: none;
      }
    }
  }
}

.footer {
  background-color: $mediumgray;
  width: 100%;

  .footerText {
    padding: 50px 0;

    a {
      padding-left: 20px;
    }
  }
}